import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './components/misc.css';
import ErrorPage from './pages/404';
import Blog from './pages/Blog';
import StrategyMatters from './components/Blog/blog-pages/StrategyMatters';
import Culture from './components/Blog/blog-pages/Culture';
import Talent from './components/Blog/blog-pages/Talent';
import Competition from './components/Blog/blog-pages/Competition';
import Guides from './components/Blog/blog-pages/Guides';
import HR from './components/Blog/blog-pages/HR';
import StartupLessons from './components/Blog/blog-pages/StartupLessons';
import KeyPrinciples from './components/Blog/blog-pages/KeyPrinciples';
import GoodDesign from './components/Blog/blog-pages/GoodDesign';
import OnlineCourse from './components/Blog/blog-pages/OnlineCourse';

function App() {
    function toText(node) {
        let tag = document.createElement('div');
        tag.innerHTML = node;
        node = tag.innerText;
        return node;
    }

    function shortenText(text, startingPoint, maxLength) {
        return text.length > maxLength
            ? text.slice(startingPoint, maxLength)
            : text;
    }

    function removeDash(arr) {
        arr.forEach((element, index) => {
            arr[index] = element.replaceAll('-', '');
        });

        return arr;
    }

    function createMarkup(obj) {
        return { __html: obj.content };
    }

    function removeSpace(arr) {
        arr.forEach((element, index) => {
            arr[index] = element.replaceAll(' ', '');
        });
        return arr;
    }

    function removeDashAndJoin(arr) {
        arr.forEach((element, index) => {
            arr[index] = element.replaceAll('-', ' ');
        });

        return arr.join(', ');
    }
    return (
        <>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/projects' element={<Projects />} />
                <Route
                    path='/blog'
                    element={
                        <Blog
                            toText={toText}
                            shortenText={shortenText}
                            removeDash={removeDash}
                        />
                    }
                />
                <Route path='/contact' element={<Contact />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='*' element={<ErrorPage />} />

                {/* Blog Pages */}
                <Route
                    path='blog/why-strategy-really-matters'
                    element={
                        <StrategyMatters
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
                <Route
                    path='blog/culture-is-possibly-one-of-the-most-underrated-arsenals-your-organisation-has'
                    element={
                        <Culture
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
                <Route
                    path='blog/finding-talent-for-growth'
                    element={
                        <Talent
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
                <Route
                    path='blog/why-competition-is-futile-for-your-startup'
                    element={
                        <Competition
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
                <Route
                    path='blog/6-guides-to-fundraising-for-your-startup'
                    element={
                        <Guides
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
                <Route
                    path='blog/how-to-hire-for-your-startup'
                    element={
                        <HR
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
                <Route
                    path='blog/what-startup-founders-should-not-be-learning'
                    element={
                        <StartupLessons
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />

                <Route
                    path='blog/series-a-1-16-key-principles-for-growth'
                    element={
                        <KeyPrinciples
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />

                <Route
                    path='blog/three-reasons-why-you-should-care-about-good-design'
                    element={
                        <GoodDesign
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />

                <Route
                    path='blog/three-steps-tocreating-an-online-course'
                    element={
                        <OnlineCourse
                            toText={toText}
                            removeDashAndJoin={removeDashAndJoin}
                            removeSpace={removeSpace}
                            createMarkup={createMarkup}
                            shortenText={shortenText}
                        />
                    }
                />
            </Routes>

            <Footer />
        </>
    );
}

export default App;
