import React from 'react';
import '../components/Projects/projects.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import youtube from '../assets/images/projects-img/youtube.png';
import tedx from '../assets/images/projects-img/tedx.png';
import lbs from '../assets/images/projects-img/lbs.png';
import pod from '../assets/images/projects-img/podcasts.png';
import { Container, Image } from 'react-bootstrap';
import GetInTouch from '../components/GetInTouch';

const Projects = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Projects | Christian Reuben</title>
                <link
                    rel='canonical'
                    href='https://christianreuben.com/projects'
                />
                <meta
                    name='description'
                    content='Christian has also given a TEDx talk on the need for Ego-free Leadership in Nigeria + an LBS research presentation on Servant Leadership. Christian has worked on projects with the EU Commission, University of Leeds, Medicines Sans Frontiers, Port Harcourt Chamber of Commerce, Touch and Pay Technologies, Lagos Business School to mention but a few.'
                />
                <meta
                    name='keywords'
                    content='Business, Consulting, Christian Reuben, Christian, Entrepreneur, Adviser, People Engineer, Writer, Speaker, Startups, Intelligence, Strategy, Consult, Contact, critical, business, planning'
                />
            </Helmet>
            <main id='projects' className=''>
                <div className='banner1'>
                    <Container>
                        <h1 className='display-4'>Personal Projects</h1>
                    </Container>
                </div>
                <Container>
                    <article
                        id='youtube'
                        className=' d-flex flex-lg-row flex-column  justify-content-between   align-items-xl-start align-items-center'>
                        <div className='details m-lg-0 mb-3'>
                            <div className='grey mb-3'></div>
                            <h2 className=''>YouTube</h2>
                            <p>
                                On Youtube, he shares invaluable insights,
                                entrepreneurial tips, and inspirational
                                interviews with industry leaders.
                                <br />
                                <br />
                                In this episode, he talks with the Chief of
                                Staff of Stears — Matinat Ajeigbe on some
                                challenges with building a leading research and
                                intelligence house in Africa; the company's
                                growth, the challenges in her role as CoS and
                                some hurdles ahead — including Stears' strategy
                                for covering the 2023 Nigerian Elections.
                            </p>
                        </div>

                        <div className='img-div '>
                            <Image
                                src={youtube}
                                alt=''
                                className='m-lg-0 mx-auto'
                                fluid
                            />
                        </div>
                    </article>

                    <article
                        id='podcast'
                        className=' d-flex flex-lg-row flex-column-reverse  justify-content-between   align-items-xl-start align-items-center'>
                        <div className='img-div'>
                            <Image
                                src={pod}
                                alt=''
                                className='m-lg-0 mx-auto'
                                fluid
                            />
                        </div>
                        <div className='details m-lg-0 mb-3'>
                            <div className='grey mb-3'></div>
                            <h2 className=''>Podcast</h2>
                            <p>
                                As a consulting expert at - BlueSense, shares
                                business and personal development insights on
                                the BlueSense Podcast.
                                <br />
                                <br />A new podcast is published bi-weekly to
                                help start-up founders and organisational team
                                thrive.' 'The series is designed to help you
                                build your company from scratch to exit / IPO.
                            </p>
                        </div>
                    </article>
                </Container>
                <div className='banner2'>
                    <Container>
                        <h1 className='display-4'>Notable Events</h1>
                    </Container>
                </div>

                <Container>
                    <article
                        id='tedx'
                        className=' d-flex flex-lg-row flex-column-reverse justify-content-between   align-items-xl-start align-items-center'>
                        <div className='img-div'>
                            <Image
                                src={tedx}
                                alt=''
                                className='m-lg-0 mx-auto'
                                fluid
                            />
                        </div>

                        <div className='details m-lg-0 mb-3'>
                            <div className='grey mb-3'></div>
                            <h2 className=''>TEDx Port Harcourt</h2>
                            <p>
                                In this compelling TEDx Port Harcourt talk,
                                Christian takes the stage to challenge our
                                perceptions of ego and its role in leadership.
                                <br />
                                <br />
                                This talk was given at a local TEDx event,
                                produced independently of the TED Conferences.
                                Ego is a highly developed sense of self, which
                                is a good thing, right? But is this a quality a
                                leader needs? Christian suggests that it is time
                                we started raising ego-free leaders.
                            </p>
                        </div>
                    </article>

                    <article
                        id='lbs'
                        className=' d-flex flex-lg-row flex-column justify-content-between   align-items-xl-start align-items-center'>
                        <div className='details m-lg-0 mb-3'>
                            <div className='grey mb-3'></div>
                            <h2 className=''>
                                Servant Leadership Literature Review
                            </h2>
                            <p>
                                Christian alongside his Department Lead - Franca
                                Ovadje PhD held a Servant Leadership Literature
                                Review at Lagos Business School. At the time of
                                the talk, he had skimmed about 79 research
                                articles on the issue and read front to back -
                                about 22 research article on the work.
                                <br />
                                <br />
                                Christian analysed the academic literature on
                                Servant Leadership and unraveling its true
                                research essence. This transformative workshop
                                left the audience inspired, motivated, and eager
                                to learn and possibly practice a new paradigm of
                                leadership.
                            </p>
                        </div>

                        <div className='img-div'>
                            <Image
                                src={lbs}
                                alt=''
                                className='d-block mx-auto'
                                fluid
                            />
                        </div>
                    </article>
                </Container>
            </main>
            <GetInTouch />
        </HelmetProvider>
    );
};

export default Projects;
