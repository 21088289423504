import React from 'react';
import { Container } from 'react-bootstrap';
import './case-studies.css';

const HomeCaseStudies = () => {
    return (
        <section id='home-cases'>
            <Container>
                <section
                    id='numbers'
                    className='d-flex flex-xl-row flex-column-reverse justify-content-between align-items-center'>
                    <div className='datas mt-lg-0 mt-sm-3 d-flex flex-md-nowrap flex-wrap justify-content-between align-items-center'>
                        <div className='data data1'>
                            <span className=''>
                                <h3>17+</h3>
                                <div className='box'></div>
                                <p>
                                    Years <br className='d-xl-none d-block' />
                                    Experience
                                </p>
                            </span>
                        </div>

                        <div className='data data2'>
                            <span className='mb-4'>
                                <h3>10+</h3>
                                <div className='box'></div>
                                <p>
                                    Companies Worked <br /> With
                                </p>
                            </span>
                        </div>

                        <div className='data data3'>
                            <span className=''>
                                <h3>7+</h3>
                                <div className='box'></div>
                                <p>
                                    Years Start-up <br />
                                    Consultant
                                </p>
                            </span>
                        </div>
                    </div>

                    <h2>
                        Our success in{' '}
                        <br className='d-xl-none d-lg-block d-none' />
                        <span>numbers</span>
                    </h2>
                </section>
            </Container>
        </section>
    );
};

export default HomeCaseStudies;
