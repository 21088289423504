import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>,
        rootElement
    );
} else {
    render(
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>,
        rootElement
    );
}

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
