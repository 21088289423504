import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import './about-mission.css';

import './about-clients.css';

import Bio from '../../assets/images/home-img/bio.svg';
import Tap from '../../assets/images/home-img/tap.svg';
import ColdHubs from '../../assets/images/home-img/cold-hubs.svg';
import Hugo from '../../assets/images/home-img/hugo-logo.svg';
import Hash from '../../assets/images/home-img/hash-logo.svg';
import Mede from '../../assets/images/home-img/mede-logo.svg';
import Icrc from '../../assets/images/home-img/icrc-logo.svg';
import Lifestores from '../../assets/images/home-img/ls-logo.svg';
import Ease from '../../assets/images/home-img/ease-logo.svg';
import Leeds from '../../assets/images/home-img/leeds-logo.svg';

const HomeAbout = () => {
    return (
        <section className='home-about'>
            <Container>
                <section id='mission'>
                    <Row
                        id='youtube'
                        className=' justify-content-between   align-items-start '>
                        <Col lg={7} className='details m-lg-0 mb-3'>
                            <div className='grey mb-3'></div>
                            <h2 className=''>Bio</h2>
                            <p>
                                Christian is an Entrepreneur and Adviser that
                                helps companies simplify challenges, build
                                productive teams and grow seamlessly; he
                                shepherds a team that helps companies grow
                                seamlessly, through analysis of the current
                                challenges (in-depth research of current
                                problems), collaboratively working with current
                                teams in creating a strategy road map for
                                business growth + then sharing the road map to
                                help companies thrive + implementing plans.
                                <br />
                                <br />
                                Christian Reuben is Lead Partner at BlueSense —
                                a business intelligence management consulting
                                company. We have deep functional, organizational
                                and industry expertise as well as the breadth of
                                a robust network. We are passionate about taking
                                on immense challenges that matter to our clients
                                and, often, to our continent and the world.
                                Christian is a trusted adviser to some of the
                                Nigeria’s leading startups, technology companies
                                and institutions; working with leading
                                organizations across the private, public and
                                social sectors with a unique expertise.
                                <br />
                                <br />
                                As an independent thinker who is naturally
                                inquisitive, Christian is not timid about
                                challenging old ideas. Garnering 15+ years of
                                leadership expertise, he is very passionate
                                about building businesses through a concrete
                                advisory approach that is appreciable in these
                                tough economic climate. Harnessing ideas with
                                his team, he has helped bring firms from
                                wrangling revenues to steady growth.
                                <br />
                                <br />
                                Christian has also given a TEDx talk on the need
                                for 'Ego-free' Leadership in Nigeria + an LBS
                                research presentation on Servant Leadership.
                                Christian has worked on projects with the EU
                                Commission, University of Leeds, Medicines Sans
                                Frontiers, Port Harcourt Chamber of Commerce,
                                Touch and Pay Technologies, Lagos Business
                                School to mention but a few.
                                <br />
                                <br />
                                Christian loves: playing his cello, yoga,
                                cycling, long walks, photography + cooking.
                            </p>
                        </Col>

                        <Col lg={4} className='img-div '>
                            <Image
                                src={Bio}
                                alt=''
                                className='d-block mx-lg-0 mx-auto'
                                fluid
                            />
                        </Col>
                    </Row>

                    <div className='circle-div mx-auto'>
                        <div className='circle circle-top mx-auto text-center d-flex flex-column justify-content-center align-items-center'>
                            <h4 className=''>Leadership</h4>
                            <p className='mb-xxl-3 mb-2'>
                                Christian’s core mission is to develop future
                                leadership in the content. He’s been a close
                                adviser and coach to a select number of
                                entrepreneurs, ceos and the leadership at some
                                key organisations around the continent.
                            </p>
                            <p>
                                As a leadership expert at his consulting agency
                                - BlueSense, he has also trained newly promoted
                                managers at a number of high-profile
                                organisations including: Medicine Sans Frontiers
                                - MSF.
                            </p>
                        </div>

                        <div className='circle-bottom d-flex flex-lg-row flex-column justify-content-center align-items-center text-center'>
                            <div className='circle circle-left me-xl-3 d-flex flex-column justify-content-center align-items-center'>
                                <h4 className=''>start-ups</h4>
                                <p className='mb-xxl-3 mb-2'>
                                    Christian's multifaceted expertise in
                                    business strategy, market analysis, and team
                                    building makes him the ideal partner for
                                    navigating the early stages of your
                                    start-up.
                                </p>
                                <p className='mb-xxl-3 mb-2'>
                                    With his guidance, you can lay a strong
                                    foundation for long-term success, empowering
                                    your team and seizing opportunities for
                                    growth.
                                </p>
                            </div>
                            <div
                                id='circle-right'
                                className='circle circle-right ms-xl-3 d-flex flex-column justify-content-center align-items-center'>
                                <h4 className=''>Companies</h4>

                                <p className='mb-xxl-3 mb-2'>
                                    Christian’s expertise caters to both
                                    aspiring small business owners and
                                    established corporations looking to grow and
                                    innovate.
                                </p>
                                <p>
                                    Whether you're at the early stages of
                                    building your company or leading a large
                                    corporation, Christian can provide the
                                    guidance and support required to achieve
                                    your goals.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='clients' className=''>
                    <p className='business mt-3 mb-sm-5 mb-4 text-start'>
                        Businesses of all sizes trust him
                    </p>
                    <Row className='pt-sm-5 pt-3 pb-4 gy-5 comp-logos'>
                        <Col
                            md={3}
                            xs={3}
                            className='d-flex  justify-content-center  align-items-center'>
                            <Image src={Tap} alt='Tap Logo' className='' />
                        </Col>
                        <Col
                            md={3}
                            xs={3}
                            className='d-flex justify-content-center  align-items-center'>
                            <Image src={ColdHubs} alt='Cold Hubs Logo' />
                        </Col>
                        <Col
                            md={3}
                            xs={3}
                            className='d-flex justify-content-center align-items-center'>
                            <Image src={Mede} alt='Mede Logo' />
                        </Col>

                        <Col
                            md={3}
                            xs={3}
                            className='d-flex  justify-content-center align-items-center'>
                            <Image src={Icrc} alt='ICRC Logo' />
                        </Col>
                        <Col
                            md={3}
                            xs={3}
                            className='d-flex justify-content-center  align-items-center'>
                            <Image
                                src={Lifestores}
                                alt='Lifestores Logo'
                                className=''
                            />
                        </Col>
                        <Col
                            md={3}
                            xs={3}
                            className='d-flex justify-content-center align-items-center'>
                            <Image src={Ease} alt='Easeme Logo' />
                        </Col>
                        <Col
                            md={3}
                            xs={3}
                            className='d-flex justify-content-center align-items-center'>
                            <Image src={Leeds} alt='Leeds Logo' />
                        </Col>

                        <Col
                            md={3}
                            xs={3}
                            className='d-flex  justify-content-center align-items-center'>
                            <Image src={Hugo} alt='Hugo Logo' />
                        </Col>

                        <Col
                            md={3}
                            xs={3}
                            className='d-flex justify-content-center align-items-center'>
                            <Image
                                src={Hash}
                                alt='Hash Logo'
                                className='mt-4'
                            />
                        </Col>
                    </Row>
                </section>
            </Container>
        </section>
    );
};

export default HomeAbout;
