import React from 'react';
import './hero.css';
import { Button, Container, Image } from 'react-bootstrap';
import Map from '../../assets/images/home-img/world-map.svg';

import { Link } from 'react-router-dom';

const HomeHero = () => {
    return (
        <section className='home-hero'>
            <Container className='text-center'>
                <p className='subtitle'>People Engineer | Writer | Speaker</p>

                <h1 className='display-3'>Entrepreneur & Adviser</h1>

                <Link
                    to='/contact'
                    className='text-decoration-none text-white mb-3'>
                    <Button className='d-block mx-auto px-md-5 py-md-4 px-4 py-3'>
                        Schedule Consultation
                    </Button>
                </Link>

                <Image
                    className='map d-block mx-auto'
                    src={Map}
                    alt='Illustration of the World Map'
                    fluid
                />
            </Container>
        </section>
    );
};

export default HomeHero;
