import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './header.css';
import { NavLink } from 'react-router-dom';
// import { Link } from 'react-router-dom';

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
        // Disable scrolling when the menu is open
        // if (!expanded) {
        //     document.body.style.overflow = 'hidden';
        // } else {
        //     document.body.style.overflow = 'auto'; // Enable scrolling when the menu is closed
        // }
    };
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleNavbarToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleLinkClick = () => {
        setIsCollapsed(true); // Close the navigation menu when a link is clicked
    };

    return (
        <header id='header' className='bg-white'>
            <Navbar
                className={`bg-white fixed overlay-menu ${
                    expanded ? 'expanded' : ''
                }`}
                expanded={!isCollapsed}
                onToggle={handleNavbarToggle}
                expand='md'>
                <Container className='flex-md-row flex-row-reverse'>
                    <Navbar.Brand className='py-2 d-flex'>
                        <h1 className='display-5 m-0'>Christian Reuben</h1>
                    </Navbar.Brand>

                    <Navbar.Toggle
                        aria-controls='basic-navbar-nav'
                        onClick={handleToggle}>
                        {/* <span className='navbar-toggler-icon'></span> */}
                        {/* ) : (
                            <span className='btn-close' />
                        )} */}
                        {expanded ? (
                            <span className='btn-close p-3'></span> // X icon
                        ) : (
                            <span className='navbar-toggler-icon'></span> // Hamburger icon
                        )}
                    </Navbar.Toggle>

                    <Navbar.Collapse
                        placement='start'
                        className='flex-md-grow-0'>
                        <Nav
                            id='header-nav'
                            className='justify-content-md-between align-items-md-center'>
                            <Nav.Item className='px-lg-4 px-md-3 px-0 pb-md-0 pb-3'>
                                <NavLink
                                    reloadDocument
                                    to='/'
                                    onClick={() => {
                                        handleLinkClick();
                                        handleToggle();
                                    }}>
                                    Home
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item className='px-lg-4 px-md-3 px-0 pb-md-0 pb-3'>
                                <NavLink
                                    reloadDocument
                                    to='/projects'
                                    onClick={() => {
                                        handleLinkClick();
                                        handleToggle();
                                    }}>
                                    Projects
                                </NavLink>
                            </Nav.Item>

                            <Nav.Item className='px-lg-4 px-md-3 px-0 pb-md-0 pb-3'>
                                <NavLink
                                    reloadDocument
                                    to='/blog'
                                    onClick={() => {
                                        handleLinkClick();
                                        handleToggle();
                                    }}>
                                    Blog
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item className='px-lg-4 px-md-3 px-0 pb-md-0 pb-4'>
                                <NavLink
                                    reloadDocument
                                    to='/contact'
                                    onClick={() => {
                                        handleLinkClick();
                                        handleToggle();
                                    }}>
                                    Contact
                                </NavLink>
                            </Nav.Item>
                        </Nav>
                        {/* <Link
                            to='/contact'
                            onClick={handleLinkClick}
                            className='my-4 d-md-none text-center d-block contact-btn text-white '>
                            Schedule Consultation
                        </Link> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
