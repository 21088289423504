import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './footer.css';
import { BsLinkedin, BsSpotify, BsYoutube } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';
import { AiFillApple } from 'react-icons/ai';
import { MdAddIcCall, MdOutlineMail } from 'react-icons/md';
import { FaMedium } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    const date = new Date()
    return (
        <footer>
            <section id='footer-top'>
                <Container>
                    <Navbar className='d-flex flex-xl-nowrap flex-wrap flex-md-row flex-column justify-content-md-between justify-content-center align-items-start'>
                        <div className='left'>
                            <Navbar.Brand className='d-flex p-0 mb-3'>
                                <h1 className='display-5'>Christian Reuben</h1>
                            </Navbar.Brand>

                            <p className='address mt-'>
                                BlueSense
                                <br />
                                WorkStation. Victoria Island
                                <br />
                                Lagos, Nigeria.
                            </p>
                        </div>

                        <div className='center'>
                            <Nav className='d-flex justify-content-between align-items-start flex-md-nowrap flex-wrap'>
                                <div className='company'>
                                    <h5 className='mb-3'>Company</h5>
                                    <Nav.Item className='mb-2'>
                                        <NavLink to='/'>Home</NavLink>
                                    </Nav.Item>

                                    <Nav.Item className='mb-2'>
                                        <NavLink to='/projects'>
                                            Projects
                                        </NavLink>
                                    </Nav.Item>

                                    <Nav.Item className='mb-2'>
                                        <NavLink to='/blog'>Blog</NavLink>
                                    </Nav.Item>

                                    <Nav.Item className=''>
                                        <NavLink to='/contact'>Contact</NavLink>
                                    </Nav.Item>
                                </div>

                                <div className='legal'>
                                    <h5 className='mb-3'>Legal</h5>

                                    <Nav.Item className='mb-2'>
                                        <NavLink to='/privacy-policy'>
                                            Privacy Policy
                                        </NavLink>
                                    </Nav.Item>
                                </div>

                                <div className='contact m-md-0 mt-5'>
                                    <h5 className='mb-3'>Contact</h5>

                                    <a
                                        href='mailto:christian.reuben@outlook.com'
                                        className='d-block mb-2'>
                                        <MdOutlineMail className='icon me-2 mb-1' />
                                        christian.reuben@outlook.com
                                    </a>

                                    <a
                                        href='tel:+2348065784615'
                                        className='d-block mb-2'>
                                        <MdAddIcCall className='icon me-2 mb-1' />
                                        +2348065784615
                                    </a>
                                </div>
                            </Nav>
                        </div>
                    </Navbar>
                </Container>
            </section>

            <section id='footer-bottom'>
                <Container className='d-flex flex-md-row flex-column-reverse justify-content-between align-items-md-center'>
                    <p className='copyright m-0 text-start'>
                        © {date.getFullYear()} Christian Reuben. All Rights Reserved.
                    </p>

                    <div className='social-icons mb-md-0 mb-3'>
                        <a
                            className='me-4 fs-4'
                            href='https://www.linkedin.com/in/christianreuben/'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <BsLinkedin />
                        </a>
                        <a
                            className='me-4 fs-4'
                            href='https://x.com/xtianreuben'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <FaXTwitter />
                        </a>
                        <a
                            className='me-4 fs-4'
                            href='https://www.youtube.com/c/ChristianReuben'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <BsYoutube />
                        </a>
                        <a
                            className='me-4 fs-4'
                            href='https://christianreuben.medium.com/'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <FaMedium />
                        </a>
                        <a
                            href='https://podcasts.apple.com/ng/podcast/bluesense-podcast/id1500778810'
                            target='_blank'
                            aria-label='Apple Podcast'
                            rel='noreferrer'
                            className='me-4 fs-3'>
                            <AiFillApple />
                        </a>

                        <a
                            href='https://open.spotify.com/show/2ipIcJs9j4ZoTMGgtHBBys?si=f16edb0d91904b4e'
                            target='_blank'
                            aria-label='Spotify Podcast'
                            rel='noreferrer'
                            className='fs-4'>
                            <BsSpotify />
                        </a>
                    </div>
                </Container>
            </section>
        </footer>
    );
};

export default Footer;
